import { lazy, Suspense } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { CONFIG } from 'src/config-global';
import { DashboardLayout } from 'src/layouts/dashboard';
import AuthGuard from '../auth/AuthGuard';
import { AuthSplitLayout } from 'src/layouts/auth-split';
import { SplashScreen, LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export function Router() {
  const layoutContent = (
    <DashboardLayout>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    </DashboardLayout>
  );

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={CONFIG.auth.redirectPath} replace />,
    },
    {
      path: 'dashboard',
      element: CONFIG.auth.skip ? <>{layoutContent}</> : <AuthGuard> {layoutContent} </AuthGuard>,

      children: [
        { element: <PageHome />, index: true },
        { path: 'transactions', element: <PageTransactions /> },
        { path: 'gas', element: <PageGas /> },
        { path: 'customers', element: <PageCustomers /> },
        { path: 'customers/:id', element: <PageCustomer /> },
        { path: 'webhooks', element: <PageWebhooks /> },
        { path: 'keys', element: <PageAPIKeys /> },
        { path: 'user', element: <PageUserSettings /> },
        { path: 'organization', element: <PageOrganizationSettings /> },
        { path: 'transactions-to-approve', element: <PageTransactionsToApprove /> },
      ],
    },
    {
      element: (
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        { path: '404', element: <Page404 /> },
        {
          path: 'sign-in',
          element: (
            <AuthSplitLayout section={{ title: 'Hi, Welcome back' }}>
              <SignInPage />
            </AuthSplitLayout>
          ),
        },
        {
          path: 'sign-up',
          element: (
            <AuthSplitLayout>
              <SignUpPage />
            </AuthSplitLayout>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <AuthSplitLayout>
              <ForgotPasswordPage />
            </AuthSplitLayout>
          ),
        },

        {
          path: 'reset-password',
          element: (
            <AuthSplitLayout>
              <ResetPasswordPage />
            </AuthSplitLayout>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const SignInPage = lazy(() => import('src/pages/auth/sign-in'));
const SignUpPage = lazy(() => import('src/pages/auth/sign-up'));

const ResetPasswordPage = lazy(() => import('src/pages/auth/reset-password'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const PageHome = lazy(() => import('src/pages/dashboard/home'));
const PageTransactions = lazy(() => import('../pages/dashboard/transactions'));
const PageGas = lazy(() => import('../pages/dashboard/gas'));
const PageCustomers = lazy(() => import('../pages/dashboard/customers'));
const PageCustomer = lazy(() => import('../pages/dashboard/customer'));
const PageAPIKeys = lazy(() => import('../pages/dashboard/keys'));
const PageUserSettings = lazy(() => import('../pages/dashboard/user'));
const PageOrganizationSettings = lazy(() => import('../pages/dashboard/organization'));
const PageWebhooks = lazy(() => import('../pages/dashboard/webhooks'));
const PageTransactionsToApprove = lazy(() => import('../pages/dashboard/transactions-to-approve'));
const Page404 = lazy(() => import('src/pages/error/404'));
