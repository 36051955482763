import 'src/global.css';

// ----------------------------------------------------------------------

import { Toaster } from 'react-hot-toast';

import { Router } from 'src/routes/router';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import { ThemeProvider } from 'src/theme/theme-provider';

import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from './auth/AuthContext';

import { GqlProvider } from './GqlProvider';

// ----------------------------------------------------------------------
export const FEEBPS = 0.005;

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <SettingsProvider settings={defaultSettings}>
        <ThemeProvider>
          <GqlProvider>
            <MotionLazy>
              <ProgressBar />
              <SettingsDrawer />
              <Router />
              <Toaster />
            </MotionLazy>
          </GqlProvider>
        </ThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}
