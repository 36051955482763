import type { ButtonBaseProps } from '@mui/material/ButtonBase';

import { useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { CONFIG } from 'src/config-global';
import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { usePopover, CustomPopover } from 'src/components/custom-popover';
import useAuth from '../../auth/useAuth';
import { useGetUserQuery, useGetOrganizationQuery } from 'src/generated/graphql';

// ----------------------------------------------------------------------

export type OrganizationsPopoverProps = ButtonBaseProps & {
  data?: {
    id: string;
    name: string;
    logo: string;
    plan: string;
  }[];
};

export function OrganizationsPopover({ sx, ...other }: OrganizationsPopoverProps) {
  const { organizationId, setOrganizationId } = useAuth();

  const [{ data: getUserData, error: getUserError }] = useGetUserQuery({});
  const user = getUserData?.getUser;

  const [{ data: getOrganizationData, error: getOrganizationError }] = useGetOrganizationQuery({
    variables: {
      organizationId: user?.organizationId ?? '',
    },
    pause: !user?.organizationId,
  });
  const organization = getOrganizationData?.getOrganization;

  const _organizations = organization
    ? [
        {
          id: organization.id,
          name: organization.name,
          logo: `${CONFIG.assetsDir}/assets/icons/workspaces/logo-1.webp`,
          plan: 'Free',
        },
      ]
    : [];

  const popover = usePopover();

  const mediaQuery = 'sm';

  const handleChangeWorkspace = useCallback(
    (newValue: (typeof _organizations)[0]) => {
      setOrganizationId(newValue.id);
      popover.onClose();
    },
    [popover]
  );

  useEffect(() => {
    if (organization && organizationId === null) {
      setOrganizationId(organization.id);
    }
  }, [organization]);

  const selectedOrganization = _organizations.find((org) => org.id === organizationId);

  if (!selectedOrganization) {
    return null;
  }

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={popover.onOpen}
        sx={{
          py: 0.5,
          gap: { xs: 0.5, [mediaQuery]: 1 },
          ...sx,
        }}
        {...other}
      >
        <Box
          component="img"
          alt={selectedOrganization?.name}
          src={selectedOrganization?.logo}
          sx={{ width: 24, height: 24, borderRadius: '50%' }}
        />

        <Box
          component="span"
          sx={{
            typography: 'subtitle2',
            display: { xs: 'none', [mediaQuery]: 'inline-flex' },
          }}
        >
          {selectedOrganization?.name}
        </Box>

        {/* <Label
          color={workspace?.plan === 'Free' ? 'default' : 'info'}
          sx={{
            height: 22,
            display: { xs: 'none', [mediaQuery]: 'inline-flex' },
          }}
        >
          {workspace?.plan}
        </Label> */}

        <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
      </ButtonBase>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'top-left' } }}
      >
        <MenuList sx={{ width: 240 }}>
          {_organizations.map((option) => (
            <MenuItem
              key={option.id}
              // selected={option.id === workspace?.id}
              onClick={() => handleChangeWorkspace(option)}
              sx={{ height: 48 }}
            >
              <Avatar alt={option.name} src={option.logo} sx={{ width: 24, height: 24 }} />

              <Box component="span" sx={{ flexGrow: 1 }}>
                {option.name}
              </Box>

              {/* <Label color={option.plan === 'Free' ? 'default' : 'info'}>{option.plan}</Label> */}
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}
