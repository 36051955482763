// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  faqs: '/faqs',
  minimalStore: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    signIn: `${ROOTS.AUTH}/sign-in`,
    signUp: `${ROOTS.AUTH}/sign-up`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    customers: `${ROOTS.DASHBOARD}/customers`,
    transactions: `${ROOTS.DASHBOARD}/transactions`,
    gas: `${ROOTS.DASHBOARD}/gas`,
    webhooks: `${ROOTS.DASHBOARD}/webhooks`,
    keys: `${ROOTS.DASHBOARD}/keys`,
    transactionsToApprove: `${ROOTS.DASHBOARD}/transactions-to-approve`,
  },
};
