import type { NavSectionProps } from 'src/components/nav-section';

import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { usePathname } from 'src/routes/hooks';

import { Logo } from 'src/components/logo';
import { Scrollbar } from 'src/components/scrollbar';
import { NavSectionVertical } from 'src/components/nav-section';

import { NavUpgrade } from '../components/nav-upgrade';
import { BaseOption } from '../../components/settings/drawer/base-option';
import { useSettingsContext } from '../../components/settings/context';
import { useColorScheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

type NavMobileProps = NavSectionProps & {
  open: boolean;
  onClose: () => void;
  slots?: {
    topArea?: React.ReactNode;
    bottomArea?: React.ReactNode;
  };
};

export function NavMobile({ data, open, onClose, slots, sx, ...other }: NavMobileProps) {
  const pathname = usePathname();
  const settings = useSettingsContext();

  const { mode, setMode } = useColorScheme();

  useEffect(() => {
    if (open) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          overflow: 'unset',
          bgcolor: 'var(--layout-nav-bg)',
          width: 'var(--layout-nav-mobile-width)',
          ...sx,
        },
      }}
    >
      {slots?.topArea ?? (
        <Box sx={{ pl: 3.5, pt: 2.5, pb: 1 }}>
          <Logo />
        </Box>
      )}

      <Scrollbar fillContent>
        <NavSectionVertical data={data} sx={{ px: 2, flex: '1 1 auto' }} {...other} />
        <NavUpgrade />

        <Box sx={{ m: 2, '& .MuiButtonBase-root': { width: '100%' } }}>
          <BaseOption
            label="Dark mode"
            icon="moon"
            selected={settings.colorScheme === 'dark'}
            onClick={() => {
              settings.onUpdateField('colorScheme', mode === 'light' ? 'dark' : 'light');
              setMode(mode === 'light' ? 'dark' : 'light');
            }}
          />
        </Box>
      </Scrollbar>

      {slots?.bottomArea}
    </Drawer>
  );
}
